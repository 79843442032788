import isNil from 'lodash-es/isNil'
import NumAbbr from 'number-abbreviate'
import { commaNumber } from '#/utils/number'
import { SCHOOL_RATING } from './schools'

const numAbbr = new NumAbbr(['K', 'M', 'B', 'T'])

type AnalyticsLayerMeta = {
  prefix?: string
  onlyCA?: boolean
  suffix?: string
  comma?: boolean
  numberOfDecimals?: number
  valueType?: string // handle as 'number' if undefined
  removeZero?: boolean
  minPercentile?: number
  maxPercentile?: number
  range?: {
    min: number
    max: number
    step?: number
  },
}

export type AnalyticsLayer = AnalyticsLayerMeta & {
  title: string
  tooltip?: string
  role?: TopHap.UserRole
  aggregation?: AnalyticsLayerMeta
  parcel?: AnalyticsLayerMeta
  hidePropertyCount?: boolean
}

export const DEFAULT_MIN_PERCENTILE_PARCEL = 0.05
export const DEFAULT_MAX_PERCENTILE_PARCEL = 0.95
export const DEFAULT_MIN_PERCENTILE_AGGREGATION = 0.03
export const DEFAULT_MAX_PERCENTILE_AGGREGATION = 0.97
export const DISTRIBUTION_COUNT = 25

export const analyticsData: {
  [key in TopHap.AnalyticsMetric]: AnalyticsLayer
} = {
  age: {
    title: 'Age',
    suffix: 'yrs',
    tooltip: 'Property Age (years)',
    numberOfDecimals: 2,
    role: 'pro'
  },
  bathrooms: {
    title: 'Bathroom Count',
    tooltip: 'Number of bathrooms per property',
    numberOfDecimals: 2,
    role: 'pro'
  },
  bedrooms: {
    title: 'Bedroom Count',
    tooltip: 'Number of bedrooms per property',
    numberOfDecimals: 0,
    role: 'pro'
  },
  census_age_median: {
    title: 'Median Age',
    suffix: 'yrs',
    numberOfDecimals: 0,
    tooltip: 'Median age of the population according to the latest US Census',
    role: 'pro'
  },
  census_collar_blue: {
    title: 'Occupations: Blue Collar',
    numberOfDecimals: 0,
    tooltip:
      'Blue collar occupations include: farming, forestry and fishing; handlers, equipment cleaners, helpers and laborers; machine operators, assemblers and inspectors; precision production, craft and repair; private household services; protective services; services other than protective and household; administrative and clerical support; sales; and transportation and material moving. (US Census)',
    role: 'pro'
  },
  census_collar_white: {
    title: 'Occupations: White Collar',
    numberOfDecimals: 0,
    tooltip:
      'White collar workers are traditionally defined as office workers. White collar occupations include: executive, administrative and managerial; professional specialties; and technicians and related support. (US Census)',
    role: 'pro'
  },
  census_divorced: {
    title: 'Divorced',
    suffix: '%',
    numberOfDecimals: 0,
    tooltip:
      'Percentage of people living in the area who are currently divorced (US Census)',
    role: 'pro'
  },
  census_educational_climate_index: {
    title: 'Educational Climate Index',
    numberOfDecimals: 0,
    tooltip:
      'This measure of socioeconomic status is based on the U.S. Census Bureau’s Socioeconomic Status (SES) measure with weights adjusted to more strongly reflect the educational aspect of social status (education 2:1 to income & occupation). Factors in this measure are income, educational achievement and occupation of persons within the ZIP code. Since this measure is based on the population of an entire ZIP code, it may not reflect the nature of an individual school.',
    role: 'pro'
  },
  census_employee_salary_average: {
    title: 'Average Employee Salary',
    comma: true,
    suffix: '$',
    numberOfDecimals: 0,
    tooltip: 'Average salary for people employed in the area',
    role: 'pro'
  },
  census_employee_salary_median: {
    title: 'Median Employee Salary',
    comma: true,
    suffix: '$',
    numberOfDecimals: 0,
    tooltip: 'Median salary for people employed in this area',
    role: 'pro'
  },
  census_family_size: {
    title: 'Average Family Size',
    numberOfDecimals: 1,
    tooltip: 'Average family size for people living in the area',
    role: 'pro'
  },
  census_household_disposable_income_median: {
    title: 'Disposable Household Income',
    numberOfDecimals: 0,
    prefix: '$',
    suffix: '/yr',
    comma: true,
    tooltip:
      'The median disposable household income (half the households have disposable income above the amount listed; half have disposable income below it).',
    role: 'pro'
  }, /* ,  census_household_income_average: {
    title: 'Household Income',
    numberOfDecimals: 0,
    prefix: '$',
    suffix: '/yr',
    comma: true,
    tooltip: 'Average income of households in the area',
    role: 'pro'
  } */
  census_household_income_median: {
    title: 'Household Income',
    numberOfDecimals: 0,
    prefix: '$',
    suffix: '/yr',
    comma: true,
    tooltip: 'Median income of households in the area',
    role: 'pro'
  },
  census_household_size: {
    title: 'Average Household Size',
    numberOfDecimals: 1,
    suffix: 'ppl',
    tooltip: 'Average size of area households in number of people',
    role: 'pro'
  },
  census_household_total_expenditure_average: {
    title: 'Household Expenditures',
    numberOfDecimals: 0,
    prefix: '$',
    suffix: '/yr',
    tooltip:
      'Average annual expenditures for food, beverages, housing, utilities, household operations, household supplies and furnishings, apparel, transportation, health care, entertainment, personal care, personal insurance, education and miscellaneous.',
    role: 'pro'
  }, /* ,  census_household_total_expenditure: {
    title: 'Total Household Expenditure',
    numberOfDecimals: 0,
    tooltip: ''
  } */
  census_households: {
    title: '# Households',
    numberOfDecimals: 0,
    tooltip:
      'Number of households in the zip code. A household is a person or group of people who occupy a housing unit. This includes rental units as an individual household.',
    role: 'pro'
  },
  census_marriage_never: {
    title: 'Never Married',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip:
      'Percentage of people living in the area who have never been married',
    role: 'pro'
  },
  census_marriage_now: {
    title: 'Now Married',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip:
      'Percentage of people living in the area who are currently married',
    role: 'pro'
  },
  census_per_capita_income: {
    title: 'Per Capita Income',
    numberOfDecimals: 0,
    prefix: '$',
    suffix: '/yr',
    tooltip: 'Average individual income of people in the area',
    role: 'pro'
  },
  census_population: {
    title: 'Population',
    numberOfDecimals: 0,
    suffix: 'ppl',
    tooltip: 'Number of people living within the zipcode',
    role: 'pro'
  },
  census_population_daytime: {
    title: 'Daytime Population',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip: 'The daytime population in this zip code',
    role: 'pro'
  },
  census_population_density: {
    title: 'Population Density',
    numberOfDecimals: 0,
    suffix: 'ppl/mi²',
    tooltip: 'The number of people living in the zip code per square mile',
    role: 'pro'
  },
  census_population_female: {
    title: 'Female Population',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip: 'Percentage of population living within the area that is female',
    role: 'pro'
  },
  census_population_male: {
    title: 'Male Population',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip: 'Percentage of population living within the area that is male',
    role: 'pro'
  },
  census_population_seasonal: {
    title: 'Seasonal Population ',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip:
      'Seasonal population derived from seasonal vacant home figures (US Census)',
    role: 'pro'
  },
  census_separated: {
    title: 'Separated',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip:
      'Percentage of people living in the area that are married but separated',
    role: 'pro'
  },
  census_travel_time_to_work: {
    title: 'Travel Time to Work',
    numberOfDecimals: 0,
    suffix: 'min',
    tooltip: 'The average commute time of workers living in this area',
    role: 'pro'
  },
  census_widowed: {
    title: 'Widowed',
    numberOfDecimals: 0,
    suffix: '%',
    tooltip: 'Percentage of people living in the area who are widowed',
    role: 'pro'
  },
  climate_drought_risk: {
    title: 'Drought Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  climate_fema_flood_risk: {
    title: 'FEMA Flood Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  climate_flood_risk: {
    title: 'Flood Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  climate_heat_risk: {
    title: 'Excessive Heat Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  climate_storm_risk: {
    title: 'Storm Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  climate_total_risk: {
    title: 'Climate Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  climate_wildfire_risk: {
    title: 'Wildfire Risk',
    numberOfDecimals: 0,
    role: 'pro'
  },
  count: {
    title: 'Property Density',
    numberOfDecimals: 0,
    tooltip: 'The count of properties located within the area',
    role: 'pro'
  },
  crime: {
    title: 'Crime Index',
    numberOfDecimals: 0,
    tooltip:
      'A score that represents the combined risks of vehicle theft, larceny, burglary, robbery, assault, murder and rape compared to the national average of 100. A score of 200 indicates twice the national average total crime risk, while 50 indicates half the national risk. The different types of crime are given equal weight in this score.',
    role: 'pro'
  },
  diffusion: {
    title: 'Diffusion',
    role: 'pro'
  },
  dom: {
    title: 'DOM',
    onlyCA: true,
    suffix: 'days',
    tooltip:
      'Days on Market (DOM) - The total number of days the listing is active on the market before an offer is accepted or the agreement between real estate broker and seller ends.',
    role: 'pro'
  },
  flattest_area_acres: {
    title: "Lot's Flat Area",
    numberOfDecimals: 2,
    role: 'pro',
    suffix: 'ac',
    tooltip: 'Largest contiguous area of a lot in acres that is flat'
  },
  flattest_parcel_ratio: {
    title: "Lot's Flat Area Percentage",
    numberOfDecimals: 2,
    role: 'pro',
    tooltip: "Percentage of a lot's area that is contiguously flat",
    suffix: '%'
  },
  elevation: {
    title: 'Elevation',
    suffix: 'ft',
    numberOfDecimals: 0,
    tooltip: 'Elevation above sea level',
    role: 'pro'
  },
  unemployment: {
    title: 'Unemployed Count',
    tooltip: 'Number of unemployed people in a county',
    numberOfDecimals: 0,
    role: 'pro'
  },
  unemployment_ratio: {
    title: 'Unemployment Rate',
    tooltip: 'The number of unemployed people as a percentage of the labor force in a county.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  estimate_accuracy: {
    title: 'TopHap Estimate Accuracy',
    numberOfDecimals: 0,
    onlyCA: true,
    suffix: '%',
    tooltip: 'Estimate accuracy of the TopHap AVM for any property or region',
    maxPercentile: 0.9,
    minPercentile: 0.1,
    role: 'basic'
  },
  estimate_rent_accuracy: {
    title: 'Rental Estimate Accuracy',
    numberOfDecimals: 0,
    onlyCA: true,
    suffix: '%',
    tooltip:
      'Rental estimate accuracy of the TopHap AVM for any property or region',
    maxPercentile: 0.9,
    minPercentile: 0.1,
    role: 'basic'
  },
  estimate_change: {
    title: 'Value Appreciation',
    numberOfDecimals: 0,
    onlyCA: true,
    suffix: '%',
    tooltip:
      'The property value percentage growth or decline over a selected time period. The period can be adjusted in the Map Legend.',
    role: 'basic'
  },
  estimate_list_price_ratio: {
    title: 'Estimate/List Price Ratio',
    role: 'pro'
  },
  estimate_rent_ppsf: {
    title: 'Rental $/ft² Estimate',
    prefix: '$',
    onlyCA: true,
    suffix: '/ft²',
    comma: true,
    numberOfDecimals: 0,
    tooltip: 'Estimated Rental Price per Square Foot',
    role: 'basic'
  },
  estimate_price: {
    title: 'TopHap Estimate',
    numberOfDecimals: 0,
    prefix: '$',
    tooltip:
      'TopHap’s proprietary automated valuation model (AVM) that estimates property values',
    role: 'basic'
  },
  estimate_ppsf: {
    title: 'TopHap Estimate $/ft² ',
    prefix: '$',
    suffix: '/ft²',
    comma: true,
    numberOfDecimals: 0,
    tooltip: 'TopHap Estimate price per square foot',
    role: 'basic'
  },
  estimate_rent_price: {
    title: 'TopHap Rental Estimate',
    numberOfDecimals: 0,
    prefix: '$',
    onlyCA: true,
    suffix: '/mo',
    tooltip: 'Median estimated rental rate for an area or property',
    role: 'basic'
  },
  estimate_sold_price_ratio: {
    title: 'Home Equity Change',
    numberOfDecimals: 1,
    suffix: '%',
    tooltip: 'The ratio of the last sale price to the current estimate.',
    role: 'expert'
  },
  garage: {
    title: 'Garage',
    numberOfDecimals: 0,
    tooltip:
      'The number of garage spaces or car port spaces properties have within the area',
    role: 'pro'
  },
  hazard_co2: {
    title: 'Carbon Monoxide Index',
    numberOfDecimals: 0,
    tooltip:
      'Carbon Monoxide is a colorless, odorless, highly poisonous gas formed by the incomplete combustion of carbon or a carbonaceous material, such as gasoline. Index score (100=National Average)',
    role: 'pro'
  },
  hazard_earthquake: {
    title: 'Earthquake Risk',
    numberOfDecimals: 0,
    tooltip:
      'The total earthquake risk based on analysis of historical earthquake magnitudes and fault locations. Index score (100=National Average)',
    role: 'pro'
  },
  hazard_hail: {
    title: 'Hail Index',
    numberOfDecimals: 0,
    tooltip:
      'The total risk of damaging hailstorms. Based on analysis of historical hail frequency and severity. Index score (100=National Average)',

    role: 'pro'
  },
  hazard_hurricane: {
    title: 'Hurricane Index',
    numberOfDecimals: 0,
    tooltip:
      'The total hurricane risk. Based on analysis of historical hurricane tracks and intensity. Index score (100=National Average).',
    role: 'pro'
  },
  hazard_lead: {
    title: 'Lead Index',
    numberOfDecimals: 0,
    tooltip:
      "A metallic element used in products like solder and paint. Lead's presence in the air may be particularly harmful to the health of infants and children. Index score (100=National Average).",
    role: 'pro'
  },
  hazard_no2: {
    title: 'NO2 Index',
    numberOfDecimals: 0,
    tooltip:
      'Poisonous brown gases, often found in auto exhaust fumes. It is a primary contributor to smog. Index score (100=National Average).',
    role: 'pro'
  },
  hazard_ozone: {
    title: 'Ozone Index',
    numberOfDecimals: 0,
    tooltip:
      'An invisible gas that irritates and impairs breathing. Index score (100=National Average).',
    role: 'pro'
  },
  hazard_particules: {
    title: 'Particulate Matter Index',
    numberOfDecimals: 0,
    tooltip:
      'Fine particles of pollutants which tend to reduce visibility and invade the lungs deeply. Index score (100=National Average).',
    role: 'pro'
  },
  hazard_pollution: {
    title: 'Air Pollution Index',
    numberOfDecimals: 0,
    tooltip:
      'A score that represents the relative air quality by combining indices of Ozone, Carbon Monoxide, Lead, Nitrogen Oxide, and Particulate Matter compared to the national average of 100. A score of 200 indicates twice the presence of air pollutants than the national average, while 50 indicates half the presence. The different types of pollution are given equal weight in this score.',
    role: 'pro'
  },
  hazard_tornado: {
    title: 'Tornado Index',
    numberOfDecimals: 0,
    tooltip:
      'The total tornado risk. Based on analysis of historical tornado tracks and intensity. Index score (100=National Average).',
    role: 'pro'
  },
  hazard_weather: {
    title: 'Weather Risk',
    numberOfDecimals: 0,
    tooltip:
      'The total risk from various storm types (hurricanes, tornadoes, damaging hailstorms and damaging wind storms). Index score (100=National Average).',
    role: 'pro'
  },
  hazard_wind: {
    title: 'Wind Index',
    numberOfDecimals: 0,
    tooltip:
      'The total risk of severe winds. Based on analysis of the frequency and severity of historical damaging windstorms, typically associated with thunderstorms. Index score (100=National Average).',
    role: 'pro'
  },
  hoa_fee: {
    title: 'HOA fee',
    numberOfDecimals: 0,
    prefix: '$',
    onlyCA: true,
    suffix: '/mo',
    tooltip:
      'Average monthly dues paid to a homeowner association (HOA), sometimes referred to as a property owners’ association (POA).',
    role: 'pro'
  },
  list_vs_sold: {
    title: 'Sold vs List',
    numberOfDecimals: 0,
    onlyCA: true,
    suffix: '%',
    tooltip: 'Ratio of reported sale price to list price',
    removeZero: true,
    role: 'pro'
  },
  living_area: {
    title: 'Living Area',
    suffix: 'ft²',
    tooltip: 'Living Area (square feet) of properties in the selected area',
    role: 'pro'
  },
  lot_size_acres: {
    title: 'Lot Size',
    numberOfDecimals: 2,
    suffix: 'ac',
    tooltip: 'Lot Size (acres) of properties in the selected area',
    role: 'pro',
    maxPercentile: 0.9,
    minPercentile: 0.1
  },
  noise: {
    title: 'Noise',
    numberOfDecimals: 0,
    onlyCA: true,
    suffix: 'dB',
    tooltip: 'Road and aviation noise in decibels (dB)',
    role: 'pro'
  },
  nri_national: {
    title: 'NRI',
    tooltip: 'The National Risk Index is defined as the potential for negative impacts as a result of a natural hazard.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_avalanche: {
    title: 'NRI Avalanche',
    tooltip: 'A mass of snow in swift motion traveling down a mountainside.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_coastal_flooding: {
    title: 'NRI Coastal Flooding',
    tooltip: 'When water inundates or covers normally dry coastal land as a result of high or rising tides or storm surges.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_cold_wave: {
    title: 'NRI Cold Wave',
    tooltip: 'A rapid fall in temperature within 24 hours and extreme low temperatures for an extended period.  The temperatures classified as a cold wave are dependent on the location and defined by the local National Weather Service (NWS) weather forecast office.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_drought: {
    title: 'NRI Drought',
    tooltip: 'A deficiency of precipitation over an extended period of time resulting in a water shortage.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_earthquake: {
    title: 'NRI Earthquake',
    tooltip: "A shaking of the earth's surface by energy waves emitted by slowly moving tectonic plates overcoming friction with one another underneath the earth's surface.",
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_hail: {
    title: 'NRI Hail',
    tooltip: "A form of precipitation that occurs during thunderstorms when raindrops, in extremely cold areas of the atmosphere, freeze into balls of ice before falling towards the earth's surface.",
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_heat_wave: {
    title: 'NRI Heat Wave',
    tooltip: 'A period of abnormally and uncomfortably hot and unusually humid weather typically lasting two or more days with temperatures outside the historical averages for a given area.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_hurricane: {
    title: 'NRI Hurricane',
    tooltip: 'A tropical cyclone or localized, low-pressure weather system that has organized thunderstorms but no front (a boundary separating two air masses of different densities) and maximum sustained winds of at least 74 mph.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_ice_storm: {
    title: 'NRI Ice Storm',
    tooltip: 'A freezing rain situation (rain that freezes on surface contact) with significant ice accumulations of 0.25 inches or greater.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_land_slide: {
    title: 'NRI Landslide',
    tooltip: 'The movement of a mass of rock, debris, or earth down a slope.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_lightning: {
    title: 'NRI Lightning',
    tooltip: 'A visible electrical discharge or spark of electricity in the atmosphere between clouds, the air and/or the ground often produced by a thunderstorm.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_riverine_flooding: {
    title: 'NRI Riverine Flooding',
    tooltip: 'When streams and rivers exceed the capacity of their natural or constructed channels to accommodate water flow and water overflows the banks, spilling out into adjacent low-lying, dry land.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_strong_wind: {
    title: 'NRI Strong Wind',
    tooltip: 'Strong Wind consists of damaging winds, often originating from thunderstorms, that are classified as exceeding 58 mph.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_tornado: {
    title: 'NRI Tornado',
    tooltip: 'A narrow, violently rotating column of air that extends from the base of a thunderstorm to the ground and is visible only if it forms a condensation funnel made up of water droplets, dust, and debris.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_tsunami: {
    title: 'NRI Tsunami',
    tooltip: 'A wave or series of waves generated by an Earthquake, Landslide, volcanic eruption, or even a large meteor hitting the ocean and causing a rise or mounding of water at the ocean surface. A Tsunami can travel across open ocean at about 500 mph and slow down to about 30 mph as it approaches land, causing it to grow significantly in height.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_volcano: {
    title: 'NRI Volcanic Activity',
    tooltip: "Volcanic Activity occurs via vents that act as a conduit between the Earth's surface and inner layers, and erupt gas, molten rock, and volcanic ash when gas pressure and buoyancy drive molten rock upward and through zones of weakness in the Earth's crust.",
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_wildfire: {
    title: 'NRI Wildfire',
    tooltip: 'An unplanned fire burning in natural or wildland areas such as forests, shrub lands, grasslands, or prairies.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  nri_winter_weather: {
    title: 'NRI Winter Weather',
    tooltip: 'Winter Weather consists of winter storm events in which the main types of precipitation are snow, sleet, or freezing rain.',
    numberOfDecimals: 2,
    role: 'pro'
  },
  owner_occupied: {
    title: 'Owner-Occupied',
    tooltip:
      'Percentage of properties within the area that are estimated to be owner-occupied',
    role: 'pro',
    aggregation: {
      suffix: '%',
      numberOfDecimals: 0
    },
    parcel: { valueType: 'boolean' }
  },
  ownership_days: {
    title: 'Ownership Time',
    suffix: 'days',
    numberOfDecimals: 0,
    tooltip: 'The number of days since the last sale date',
    role: 'pro'
  },
  permits_count: {
    title: 'Permits Count',
    numberOfDecimals: 0,
    tooltip: 'Number of issued permits within the area',
    role: 'expert'
  },
  permits_value: {
    title: 'Permits Value',
    numberOfDecimals: 0,
    prefix: '$',
    tooltip: 'Declared value of issued permits within the area',
    role: 'expert'
  },
  pool: {
    title: 'Has Pool',
    tooltip: 'Percentage of properties that have a pool within the area',
    role: 'pro',
    aggregation: {
      numberOfDecimals: 0,
      suffix: '%',
      valueType: 'number'
    },
    parcel: { valueType: 'boolean' }
  },
  precipitation: {
    title: 'Rainfall',
    numberOfDecimals: 0,
    suffix: 'in',
    tooltip: 'Combined average annual rainfall and snowfall in inches',
    role: 'pro'
  },
  price_per_sqft: {
    title: 'Last Sale $/ft²',
    prefix: '$',
    suffix: '/ft²',
    comma: true,
    numberOfDecimals: 0,
    removeZero: true,
    tooltip: 'Last Sale Price per Square Foot',
    role: 'pro'
  },
  profit: {
    title: 'Profitability',
    prefix: '$',
    numberOfDecimals: 2,
    tooltip:
      'The difference between the last sold price and the previous sold price',
    role: 'pro'
  },
  property_use: {
    title: 'Property Type',
    valueType: 'string',
    tooltip:
      'The composition of types of properties within this area including (single-family house, condo, multi-family)',
    role: 'pro'
  },
  rent_yield: {
    title: 'Gross Rental Yield',
    onlyCA: true,
    suffix: '%',
    numberOfDecimals: 0,
    tooltip:
      'Gross Rental Yield is calculated by taking the "estimated annual rental income" and dividing by the "estimated property value"',
    role: 'expert'
  },
  sale_price: {
    title: 'Last Sale Price',
    prefix: '$',
    numberOfDecimals: 0,
    removeZero: true,
    tooltip: 'Last recorded sale price of the property',
    role: 'pro'
  },
  school_enrollment: {
    title: 'Number of Students',
    numberOfDecimals: 0,
    suffix: 'ppl',
    tooltip: ' Number of students enrolled in a school within school attendance area.',
    role: 'pro',
    hidePropertyCount: true
  },
  school_school_rating: {
    title: 'School Rating',
    numberOfDecimals: 0,
    tooltip:
      'School letter rating from A+ to F, A+ being the best.',
    role: 'pro',
    hidePropertyCount: true
  },
  school_student_teacher_ratio: {
    title: 'Students per Teacher',
    numberOfDecimals: 1,
    tooltip: 'Average number of students per teacher in specific school zone.',
    role: 'pro',
    hidePropertyCount: true
  },
  slope: {
    title: 'Lot Slope',
    suffix: '%',
    numberOfDecimals: 0,
    tooltip:
      'The average slope percentage of the lot. This measures how flat or steep a lot is and can be a good indicator of the buildability or cost of building of a lot as well as potential attributes such as view or usable yard area.',
    role: 'pro'
  },
  sold_date: {
    title: 'Ownership Time',
    suffix: 'days',
    numberOfDecimals: 2,
    tooltip: 'Duration of current ownership (days)',
    role: 'pro'
  },
  stories: {
    title: 'Stories',
    numberOfDecimals: 0,
    tooltip:
      'The number of levels, floors, or stories that buildings have within the area',
    role: 'pro'
  },
  tax: {
    title: 'Taxes',
    prefix: '$',
    numberOfDecimals: 2,
    tooltip: 'Amount of last paid property tax',
    role: 'expert'
  },
  tax_rate: {
    title: 'Tax % of Assessed Value',
    suffix: '%',
    numberOfDecimals: 2,
    tooltip: '% of Total Assessed Value Paid in Taxes',
    role: 'expert'
  },
  temperature: {
    title: 'Temperature',
    suffix: '°F',
    numberOfDecimals: 1,
    tooltip: 'Seasonal temperature (°F) sampled over multiple years',
    role: 'pro'
  },
  turnover: {
    title: 'Turnover',
    suffix: '%',
    numberOfDecimals: 2,
    tooltip:
      'The percentage of properties within the area that have changed ownership in the selected time period. Time period can be adjusted in the Map Legend',
    role: 'pro'
  },
  unique_zones: {
    title: 'Unique Zones',
    valueType: 'string',
    tooltip:
      'Homes grouped together by their common neighborhoods, school zones, zip codes, etc...',
    role: 'pro'
  },
  usable_area: {
    title: 'Lot Usable Space',
    suffix: '%',
    numberOfDecimals: 2,
    tooltip:
      'The percentage of the lot currently not utilized by the footprint of the property. Footprint is calculated as Living area / # Stories / Lot Size.',
    role: 'pro'
  },
  walkability: {
    title: 'Walkability',
    numberOfDecimals: 0,
    tooltip:
      'Walkability is scored according to the National Walkability Index. Walkability depends upon characteristics of the built environment that influence the likelihood of walking being used as a mode of travel.',
    role: 'pro'
  },
  zoned_code: {
    title: 'Zoning Code',
    valueType: 'string',
    tooltip: 'County specific zoning code.',
    role: 'pro'
  }
} as any

export function getAnalyticsMetricData(
  metric: TopHap.AnalyticsMetric,
  level: 'aggregation' | 'parcel'
) {
  const data = analyticsData[metric]
  const additional = level === 'aggregation' ? data.aggregation : data.parcel
  const merged = {
    ...data,
    ...(additional || {})
  }

  merged.valueType = merged.valueType || 'number'
  return merged
}

export function formatDays(days: number) {
  const year = Math.floor(days / 365)
  const month = (days - year * 365) / 30

  let formatted = ''
  if (year > 0) formatted = formatted + year + 'y '
  if (month > 0) formatted = formatted + Math.round(month) + 'mo '

  return formatted
}

export function formatAnalyticsMetric(
  metric: TopHap.AnalyticsMetric,
  value: string | number,
  level: 'aggregation' | 'parcel'
): string {
  const dataMapping = getAnalyticsMetricData(metric, level)
  const valueType = dataMapping.valueType
  let formatted

  if (valueType === 'boolean') {
    formatted = String(value)
  } else if (valueType === 'number' && dataMapping.suffix === 'days') {
    formatted = formatDays(Number(value))
  } else if (dataMapping.title === 'School Rating') {
    formatted = String(SCHOOL_RATING[Number(value)])
  } else {
    if (valueType === 'number') {
      formatted = dataMapping.comma ?
        commaNumber(
          isNil(dataMapping.numberOfDecimals) ?
            value :
            Number(value).toFixed(dataMapping.numberOfDecimals)
        ) :
        numAbbr.abbreviate(
          isNil(dataMapping.numberOfDecimals) ?
            value :
            Number(value).toFixed(dataMapping.numberOfDecimals),
          1
        )
    } else {
      formatted = value
    }

    if (dataMapping.prefix) formatted = dataMapping.prefix + formatted
    if (dataMapping.suffix) formatted = formatted + ' ' + dataMapping.suffix
  }

  return formatted
}
