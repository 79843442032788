export const SchoolTag = {
  national_school_lunch_program_flag: 'National School Lunch Program',
  magnet_flag: 'Magnet',
  charter_flag: 'Charter',
  bilingual_education_flag: 'Bilingual Education',
  balanced_schedule_flag: 'Balanced Schedule',
  before_and_after_school_prog_flag: 'Before and After School Program'
}

export const SchoolRatingColor: Record<string, string> = {
  'A-': '#449b29',
  A: '#449b29',
  'A+': '#449b29',
  'B-': '#a0d545',
  B: '#a0d545',
  'B+': '#a0d545',
  'C-': '#fdc716',
  C: '#fdc716',
  'C+': '#fdc716',
  'D-': '#f15634',
  D: '#f15634',
  'D+': '#f15634',
  'F-': '#cf0f0f',
  F: '#cf0f0f',
  'F+': '#cf0f0f'
}

export const SCHOOL_RATING = [
  'F',
  '< D+',
  'C-',
  'C',
  'C+',
  'B-',
  'B',
  'B+',
  'A-',
  'A',
  'A+'
]
