import { ClickAwayListener } from '@mui/material'
import MaterialTooltip, { TooltipProps as MaterialTooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import cn from '#/utils/classnames'
import styles from './styles.module.scss'

interface TooltipProps {
  placement?: MaterialTooltipProps['placement']
  tooltip: React.ReactNode
  trigger: 'click' | 'hover'
  wrap: boolean
  children: React.ReactNode
  underline?: boolean
  wrapClassName?: string
  disableTouchListener?: boolean
}

export default function Tooltip({
  disableTouchListener,
  children,
  placement,
  tooltip,
  trigger,
  underline = true,
  wrap,
  wrapClassName
}: TooltipProps) {
  const [isOpen, setOpen] = React.useState(false)

  if (!tooltip) {
    return wrap ? (
      <div className={wrapClassName}>{children}</div>
    ) : (
      (children as React.ReactElement)
    )
  }

  function close() {
    setOpen(false)
  }

  function open() {
    setOpen(true)
  }

  const commonProps = {
    arrow: true,
    classes: {
      tooltip: styles['th-tooltip-overlay'],
      popper: styles['th-tooltip-popper'],
      arrow: styles['th-tooltip-arrow']
    },
    placement,
    title: tooltip,
    disableFocusListener: false,
    disableTouchListener
    // PopperProps: {
    //   popperOptions: {
    //     modifiers: {
    //       arrow: {
    //         enabled: Boolean(refArrow.current),
    //         element: refArrow.current
    //       }
    //     }
    //   }
    // }
  }

  if (trigger === 'click') {
    return (
      <ClickAwayListener onClickAway={close}>
        <div style={{ display: 'inline-block' }}>
          <MaterialTooltip
            {...commonProps}
            PopperProps={{ disablePortal: true }}
            disableHoverListener
            leaveTouchDelay={0}
            open={isOpen}
            onClose={close}
          >
            <div
              className={cn(styles['th-tooltip-trigger'], { [styles['th-underline']]: underline })}
              onClick={open}
            >
              {children}
            </div>
          </MaterialTooltip>
        </div>
      </ClickAwayListener>
    )
  } else {
    return (
      <MaterialTooltip {...commonProps} disableInteractive disableTouchListener={false}>
        {wrap ? (
          <div className={cn(styles['th-tooltip-wrapper'], wrapClassName)}>
            {children}
          </div>
        ) : (
          (children as React.ReactElement)
        )}
      </MaterialTooltip>
    )
  }
}

Tooltip.defaultProps = {
  trigger: 'click',
  wrap: true
}
